
import { Component, Prop } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Terms from '@/models/Terms'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import ViewModel from '@/models/ViewModel'
// @ts-ignore
import CKEditor from '@ckeditor/ckeditor5-vue2'
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import FormInput from '@/components/FormInput/FormInput.vue'

@Component({
  components: {
    Widget,
    SelectPicker,
    ckeditor: CKEditor.component,
    FormInput,
  },
})
export default class TermsEdit extends ViewModel {
  @Prop()
  public id!: string;

  public title = 'Create Terms';

  public loading = false;

  public busy = true;

  public terms: Terms = new Terms();

  public editor = ClassicEditor;

  public terms_types: any = [
    {
      name: 'Advertiser',
      value: 'advertiser',
    },
    {
      name: 'Publisher',
      value: 'publisher',
    },
  ];

  public onSubmit() {
    this.loading = true
    this.terms
      .save()
      .then(response => {
        this.loading = false
        if (response.status == 200) {
          this.$router.push({ name: 'TermsHome' })
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  public cancel() {
    this.$router.push({ name: 'TermsHome' })
  }

  public created() {
    if (this.id) {
      this.busy = true
      Terms.get(this.id).then(o => {
        if (o instanceof Terms) {
          this.terms = o
          this.title = 'Edit Terms'
        } else {
          this.terms = new Terms()
          this.title = 'Create Terms'
        }
        this.busy = false
      })
    } else {
      this.busy = false
    }
  }
}
