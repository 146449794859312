
import { Component, Ref } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Terms from '@/models/Terms'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
  },
})
export default class TermsHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public term: Terms = new Terms()

  public sort_by: string = 'updated_at'

  public sort_desc: boolean = true

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public query: string[] = []

  public modal: any = {
    delete: false,
  }

  public fields: any = [
    {
      key: 'name',
      label: 'Name',
      sortable: true,
      show: true,
    },
    {
      key: 'type',
      label: 'Type',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'created_at',
      label: 'Created At',
      sortable: true,
      class: 'text-center',
      show: false,
    },
    {
      key: 'updated_at',
      label: 'Updated At',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'action',
      label: 'Action',
      sortable: false,
      show: true,
    },
  ]

  public query_settings: any = {
    custom_fields: [
      {
        name: 'type:publisher',
        value: 'type:publisher',
      },
      {
        name: 'type:advertiser',
        value: 'type:advertiser',
      },
    ],
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public terms(context: any) {
    return Terms.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: context.filter,
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public createTerms() {
    this.$router.push('/app/term')
  }

  public editTerms(id: string) {
    this.$router.push(`/app/term/${id}`)
  }

  public deleteInsertionOrder() {
    if (this.term.id) {
      this.term.delete().then(() => {
        this.dataTable.refresh()
      })
    }
  }

  public confirmDelete(term: Terms) {
    this.term = term
    this.modal.delete = true
  }
}
